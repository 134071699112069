import { Button, Card, Descriptions, Result } from 'antd';
import { useNavigate } from 'react-router';
import { useBuildingConfig } from '../useBuildingConfig';
import DevInfoContact from './DevInfoContact';

export function IfRoomNotConfigureInfo() {
	const navigate = useNavigate();
	const { currentSelectedBuildingId } = useBuildingConfig();

	return (
		<Result
			status="warning"
			title="请先配置楼宇与房间价格"
			extra={
				<>
					<Card title="自行配置">
						<Descriptions bordered={true} layout="vertical" className="mt-6">
							<Descriptions.Item label="楼宇配置">
								<Button
									type="link"
									onClick={() => {
										navigate('/apartment/buildingConfigure');
									}}
								>
									进入
								</Button>
							</Descriptions.Item>
							<Descriptions.Item label="房间配置">
								<Button
									type="link"
									onClick={() => {
										navigate(
											`/apartment/roomConfigure/${currentSelectedBuildingId}`,
										);
									}}
								>
									进入
								</Button>
							</Descriptions.Item>
						</Descriptions>
					</Card>
					<Card title="寻找管理员为您配置">
						<DevInfoContact />
					</Card>
				</>
			}
		/>
	);
}
