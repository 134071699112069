import { useLocalStorageState } from 'ahooks';
import type { RouterOutputs } from '../../../utils/trpc';

export function useSelectRoom() {
	const [room, setRoom] = useLocalStorageState<
		RouterOutputs['apartment']['getRooms'][0]
	>('last-select-room-state');

	const roomNumber = room?.roomNumber;

	return { room, setRoom, roomNumber };
}
