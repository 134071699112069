import dayjs from 'dayjs';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import type { useApartmentList } from './useApartmentList';

export function ifIncludeTheTime(
	i: ReturnType<typeof useApartmentList>['list'][number],
	formTime: dayjs.Dayjs,
): boolean {
	return (
		dayjs(i.checkInTime).isBetween(
			startOfMonth(formTime),
			endOfMonth(formTime),
			'day',
			'[]',
		) &&
		dayjs(i.checkOutTime).isBetween(
			startOfMonth(formTime),
			endOfMonth(formTime),
			'day',
			'[]',
		)
	);
}
