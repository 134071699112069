import classNames from 'classnames';
import { UsedTrendView } from '../edit/UsedTrendView';
import styles from './overview.module.css';

export function usedChartTrend(isPC: boolean, rentItems, history) {
	return isPC ? (
		<div className={classNames('flex flex-row')}>
			<div
				className={classNames(
					'basis-2/5 overflow-y-auto h-96',
					styles['pretty-bar'],
				)}
			>
				{rentItems}
			</div>

			<div
				className={classNames(
					'basis-3/5 overflow-y-auto h-96',
					styles['pretty-bar'],
				)}
			>
				<UsedTrendView roomLastInfoList={history} />
			</div>
		</div>
	) : (
		<>
			{rentItems}
			<UsedTrendView roomLastInfoList={history} />
		</>
	);
}
