import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './overview.module.css';
import classNames from 'classnames';
import { roomStatusEnumeration } from 'blog/type-file';

export function Operation(room: any) {
	const navigate = useNavigate();

	return (
		<div className={styles.panel} style={{ height: 40 }}>
			{
				<div className="flex flex-row gap-3">
					<Button
						disabled={room?.roomStatus !== roomStatusEnumeration.open}
						onClick={() => {
							navigate(
								`/apartment/entering?category=checkIn&roomNumber=${room.roomNumber}`,
							);
						}}
					>
						入住
					</Button>
					<Button
						disabled={room?.roomStatus !== roomStatusEnumeration.busy}
						onClick={() => {
							navigate(
								`/apartment/entering?category=monthlyStatement&roomNumber=${room.roomNumber}`,
							);
						}}
					>
						月结
					</Button>
					<Button
						disabled={room?.roomStatus !== roomStatusEnumeration.busy}
						onClick={() => {
							navigate(
								`/apartment/entering?category=lease&roomNumber=${room.roomNumber}`,
							);
						}}
					>
						退租
					</Button>
				</div>
			}
		</div>
	);
}
